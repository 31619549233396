const AUTH_TOKEN = 'auth_token';
const USER_ID = 'user_id';
const USER_STATUS = 'user_status';
const RCS = 'rcs';

export const removeLocalItem = (item) => localStorage.removeItem(item);
export const setLocalItem = (key, value) => localStorage.setItem(key, value);
export const getLocalItem = (item) => localStorage.getItem(item);

export const setUserToken = (token) =>
  typeof token == 'string' && setCookie(AUTH_TOKEN, token, 365, { secure: true });
export const getUserToken = (_) => getCookie(AUTH_TOKEN);
export const removeUserToken = () => removeCookie(AUTH_TOKEN, { secure: true });
export const isGuestUser = () => getCookie('user_status') === 'GUEST';

export const setRcsToken = (rcs) => typeof rcs == 'string' && setLocalItem(RCS, rcs);
export const getRcsToken = () => getLocalItem(RCS);

export const setUserId = (userId) => setCookie(USER_ID, userId, 365, { secure: true });
export const getUserId = () => getCookie(USER_ID);
export const removeUserId = () => removeCookie(USER_ID, { secure: true });

export const setGuestUser = (token, userId, status) => {
  setCookie(AUTH_TOKEN, token, 365, { secure: true });
  setCookie(USER_ID, userId, 365, { secure: true });
  setCookie(USER_STATUS, status, 365, { secure: true });
};
export const removeUserStatus = () => removeCookie(USER_STATUS, { secure: true });

// Function to set a cookie (with Secure and SameSite options)
function setCookie(name, value, days, options = {}) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // Convert days to milliseconds
    expires = '; expires=' + date.toUTCString();
  }

  // Default options for security
  const secure = options.secure ? '; Secure' : ''; // Secure: cookie sent only over HTTPS
  const sameSite = options.sameSite ? `; SameSite=${options.sameSite}` : '; SameSite=Lax'; // SameSite=Lax by default

  // Set the cookie
  document.cookie =
    name + '=' + encodeURIComponent(value) + expires + '; path=/' + secure + sameSite;
}

// Function to get a cookie by name
function getCookie(name) {
  const nameEQ = name + '=';
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();
    if (cookie.indexOf(nameEQ) === 0) {
      return decodeURIComponent(cookie.substring(nameEQ.length));
    }
  }
  return null;
}

// Function to remove a cookie by setting it to expire in the past
function removeCookie(name, options = {}) {
  // Set expiration to a past date to delete the cookie
  const secure = options.secure ? '; Secure' : '';
  const sameSite = options.sameSite ? `; SameSite=${options.sameSite}` : '; SameSite=Lax';

  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/' + secure + sameSite;
}
